import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locale from 'element-ui/lib/locale'
import zhCNLocale from 'element-ui/lib/locale/lang/zh-CN'
import enLocale from 'element-ui/lib/locale/lang/en'
import esLocale from 'element-ui/lib/locale/lang/es'
import ptLocale from 'element-ui/lib/locale/lang/pt'
import jaLocale from 'element-ui/lib/locale/lang/ja'
import koLocale from 'element-ui/lib/locale/lang/ko'
import trLocale from 'element-ui/lib/locale/lang/tr-TR'
import zh_CN from '@/lang/zh-CN.js'
import en from '@/lang/en.js'
import es from '@/lang/es.js'
import pt from '@/lang/pt.js'
import ja from '@/lang/ja.js'
import ko from '@/lang/ko.js'
import tr from '@/lang/tr.js'

Vue.use(VueI18n)

const messages = {
    'zh-CN': Object.assign(zhCNLocale, zh_CN),
    'en': Object.assign(enLocale, en),
    'es': Object.assign(esLocale, es),
    'pt': Object.assign(ptLocale, pt),
    'ja': Object.assign(jaLocale, ja),
    'ko': Object.assign(koLocale, ko),
	'tr': Object.assign(trLocale, tr),
}
//将vuei18n实例化，并传入配置信息
const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || 'tr', //当前语种，locale用来设置语种包
    fallbackLocale: 'tr', //当前语种不存在时，默认设置的当前语种
    messages, //本地语言包
})
locale.i18n((key, value) => i18n.t(key, value)) //为了实现element插件的多语言切换

export default i18n
