export default {
    "Module": {
        "Home": "Home",
        "PerpetualContract": "Perpetual futures",
        "CurrencyTrading": "Currency transaction",
        "SecondContract": "Options contract",
        "Finance": "Finance",
		"OurTeam": "Our team",
        "Announcement": "Announcement",
        "Assets": "Assets",
        "Account": "Account"
    },
    "Login": {
        "Login": "Login",
        "Register": "Register",
        "RetrievePassword": "Retrieve password",
        "Type": "Email",
        "Email": "Email",
        "Password": "Password",
        "ConfirmPassword": "Confirm Password",
        "VerificationCode": "Verification code",
        "Send": "Send",
        "PhoneNumber": "Phone number",
        "InvitationCode": "Invitation code",
        "Agree": "I have read and agree",
        "UserAgreement": "User Agreement",
        "EnterEmail": "Please enter your email",
        "EnterEmailError": "Please enter the correct email format",
        "EnterPassword": "Please enter your password",
        "EnterPasswordError": "Password format is 6 to 20 characters",
        "EnterConfirmPassword": "Please confirm your password",
        "EnterConfirmPasswordError": "The passwords entered twice are inconsistent",
        "EnterEmailFirst": "Please enter your email first",
        "EnterVerificationCode": "please enter verification code",
        "EnterPhoneNumber": "Please enter your mobile phone number",
        "EnterInvitationCode": "Please enter the invitation code",
        "ConfirmAgreement": "Please read and agree to the User Agreement",
        "Forget": "Forget the password?"
    },
    "Home": {
        "TcTitle": "Proclamation",
        "TopTitle1": "Start your cryptocurrency investment quickly",
        "TopTitle2": "NEODEAL is the most trusted and safest place to buy and sell cryptocurrencies",
        "EmailAddress": "Email address",
        "StartUsing": "Start using",
        "SeeMore": "See more",
        "MarketTrend": "Market trend",
        "Currency": "Currency",
        "LatestPrice": "Latest price",
        "Change": "24H Change",
        "TradingVolume": "24H Trading volume",
        "Trend": "Trend",
        "Operate": "Operate",
        "Trade": "Trade",
        "AllTradingVolume": "Exchange 24H trading volume",
        "SupportedCountries": "Supported countries",
        "RegisterNumber": "Number of registered users",
        "Box4Title": "Most Trusted Cryptocurrency Platform",
        "Box4Desc": "Here are the reasons why you should choose NEODEAL",
        "Box4TabTitles": [
            "Diversified transactions",
            "Minimum Fee",
            "Leading security",
            "Ultimate User Experience"
        ],
        "Box4TabDescs": [
            "NEODEAL provides an order book with top liquidity, rich cryptocurrency trading and asset management services to meet diverse trading needs.",
            "By providing traders with access to the peer-to-peer financing market, NEODEAL chooses specific cryptocurrency spot trading pairs with the lowest transaction fees.",
            "NEODEAL adopts an offline storage method, and personal transaction data is guaranteed through end-to-end encryption, and the platform monitors in real time to fully protect the security of user assets.",
            "User first, easy to use, listening to feedback, fast product optimization, 7*24 hours real-time online, multilingual one-to-one professional services."
        ],
        "Box5Title": "Constantly enriching product lines to meet the needs of various users",
        "Box5TabTitles": [
            "Perpetual futures",
            "Currency transaction",
            "Options contract",
            "Finance"
        ],
        "Box5TabDescs": [
            "Leverage trading, using leverage to use its own funds as a margin, borrow N times the funds from the platform as the principal, and make investments to obtain profits.",
            "Use a certain digital currency as the pricing unit, buy other types of digital currency, quickly buy and sell cryptocurrencies at the best price, and obtain the best returns.",
            "Financial derivatives, by analyzing short-term market conditions, traders buy or sell at a predetermined price at a specific time, and trade at a fast frequency to earn profits.",
            "Competitive high annualized rate of return, first-class capital security guarantee, easy to earn income!"
        ],
        "TradeNow": "Trade now",
        "Box6Title1": "Start transactions anytime, anywhere",
        "Box6Title2": "Both APP and WEB can quickly open transactions",
        "FooterItems1": [
            "Terms and Policies",
            "Rate standard",
            "Risk warning",
            "Anti money laundering",
            "About Us"
        ],
        "FooterItems2": [
            "Service Privacy",
            "Terms of Service",
            "Privacy statement",
            "White paper",
            "MSB certification"
        ],
        "FooterItems3": [
            "Help center",
            "Beginner's Guide",
            "Operation Guide",
            "Contact us"
        ]
    },
    "Trade": {
        "EnterCurrencyName": "Please enter a currency name",
        "Currency": "Currency",
        "LatestPrice": "Latest price",
        "Change": "Change",
        "Highest": "24H High",
        "Lowest": "24H Low",
        "OrderBook": "Order book",
        "LatestRecord": "Latest record",
        "All": "All",
        "Bids": "Bids",
        "Asks": "Asks",
        "Price": "Price",
        "Quantity": "Quantity",
        "Total": "Total",
        "Time": "Time",
        "AveragePrice": "Average price",
        "EnterPayPass": "Please enter the transaction password",
        "BuyTips": "Are you sure you want to buy?",
        "type": "Type"
    },
    "PerpetualContract": {
        "OrderType": "Order type",
        "Market": "Market",
        "EnterQuantity": "Enter the purchase quantity",
        "HandlingFee": "Handling fee",
        "AvailableBalance": "Available balance",
        "Lever": "Lever",
        "Fee": "Fee",
        "OpenLong": "Open long",
        "OpenShort": "Open short",
        "CurrentOrders": "Current order",
        "OrderHistory": "Order history",
        "Margin": "Margin",
        "CurrentPrice": "Current price",
        "OpeningPrice": "Opening price",
        "UnsettledProfit": "Unsettled profit",
        "Operate": "Operate",
        "OpenTime": "Open time",
        "ClosingTime": "Closing time",
        "ClosingPrice": "Closing price",
        "SettlementProfit": "Settlement profit",
        "ClosePosition": "Close position",
        "Edit": "Edit",
        "EditOrder": "Edit order",
        "EnterCloseQuantity": "Please enter the quantity",
        "ConfirmOrder": "Confirm order",
        "TakeProfitPrice": "Take profit price",
        "StopPrice": "Stop price"
    },
    "CurrencyTrading": {
        "Buy": "Buy",
        "Sell": "Sell",
        "MyBalance": "My balance",
        "AvailablePurchaseAmount": "Amount available for purchase",
        "ConvertibleAmount": "Convertible amount",
        "CurrentPrice": "Current price",
        "BuyQuantity": "Buy quantity",
        "EnterBuyQuantity": "Enter the quantity to buy",
        "SellQuantity": "Sell quantity",
        "EnterSellQuantity": "Enter the quantity to sell",
        "RequiredAmount": "Required amount",
        "ExchangeAmount": "Exchange amount",
        "OrderHistory": "Order history",
        "Status": "Status",
        "Success": "Success"
    },
    "SecondContract": {
        "Buy": "Buy",
        "OpeningQuantity": "Opening quantity",
        "EnterOpeningQuantity": "Enter currency amount",
        "SelectQuantity": "Select quantity",
		"SelectMultiple": "Select multiple",
        "OpeningTime": "Opening time",
        "minute": "minute",
        "Balance": "Balance",
        "Transfer": "Transfer",
        "HandlingFee": "Handling fee",
        "Profitability": "Profitability",
        "BuyUp": "Buy up",
        "BuyDown": "Buy down",
        "InTransaction": "In transaction",
        "Closed": "Closed",
        "OrderNumber": "Order number",
        "CurrentPrice": "Current price",
        "Direction": "Direction",
        "EstimatedProfitLoss": "Estimated profit and loss",
        "Countdown": "Countdown",
        "OpeningPrice": "Opening price",
        "ClosingPrice": "Closing price",
        "ProfitAndLoss": "Profit and loss"
    },
    "Finance": {
        "TopTitle": "Finance treasure",
        "TopDesc": "It is a blockchain asset holding currency to earn interest, stable income investment platform, including fixed (locked financial management), current financial management and other types of products, providing the best investment channel for stable investment users",
        "AllProducts": "All products",
        "InProgress": "In progress",
        "AboutToStart": "About to start",
        "ItsOver": "It's over",
        "FinancialRecords": "Financial records",
        "ReferenceAnnualized": "Reference annualized",
        "day": "day",
        "hour": "hour",
        "minute": "minute",
        "second": "second",
        "Unlock": "Unlock",
        "RemainingPosition": "Remaining position",
        "LockedPositions": "Locked positions",
        "EndOfDistance": "End of distance",
        "JoinNow": "Join now",
        "OurAdvantage": "Our advantage",
        "AdvanListTitles": [
            "Efficient income",
            "Shorter cycle",
            "Safe and reliable"
        ],
        "AdvanListDescs": [
            "Use a variety of professional financial tool combinations to create high-quality high-yield financial products for users",
            "The cycle is more flexible, and the lock-up is as short as 7 days",
            "Professional management and risk control solutions provide protection for asset returns"
        ],
        "JoinNum": "{joinNum} people have joined",
        "DailyRate": "Daily rate",
        "StartingTime": "Starting time",
        "LockPeriod": "Lock-up period",
        "RebateMethod": "Rebate method",
        "RebateMethod1": "Principal return at maturity",
        "RebateMethod2": "Daily rebate",
        "LockPosition": "Lock position",
        "ProductSpecification": "Product specification",
        "LockedCurrency": "Locked currency",
        "SubscriptionAmount": "Subscription amount",
        "MinAndMax": "Minimum/Maximum",
        "TotalPosition": "Total position",
        "TradingRules": "Trading rules",
        "BuyProductTips": "Are you sure you want to subscribe to this product?",
        "Pending": "Pending",
        "Completed": "Completed",
        "ProductName": "Product name",
        "PurchaseAmount": "Purchase amount",
        "CumulativeIncome": "Cumulative income",
        "EndTime": "End Time"
    },
    "Assets": {
        "FinancialRecords": "Financial records",
        "Deposit": "Deposit",
        "Withdraw": "Withdraw",
        "Transfer": "Transfer",
        "TotalBalance": "Total balance",
        "Refresh": "Refresh",
        "Currency": "Currency",
        "AvailableBalance": "Available balance",
        "Frozen": "Frozen",
        "Operate": "Operate",
        "Type": "Type",
        "Time": "Time",
        "Quantity": "Quantity",
        "SelectCurrency": "Select currency",
        "Address": "Address",
        "Notice": "Notice",
        "DepositRecord": "Deposit record",
        "OrderNumber": "Order number",
        "Status": "Status",
        "Status1": "In progress",
        "Status2": "Completed",
        "Status3": "Fail",
        "Status4": "Abnormal",
        "Status5": "Cancelled",
        "WithdrawalAmount": "Withdrawal Amount",
        "Least": "Least",
        "All": "All",
        "WithdrawalCurrency": "Withdrawal currency",
        "WithdrawalAddress": "Withdrawal address",
        "HandlingFee": "Handling fee",
        "ReceiptAmount": "Receipt amount",
        "TransactionPassword": "Transaction password",
        "SetTransactionPassword": "Set transaction password",
        "GoogleVerificationCode": "Google verification code",
        "EnterWithdrawalAmount": "Please enter the withdrawal amount",
        "EnterWithdrawalAddress": "Please enter the withdrawal address",
        "EnterTransactionPassword": "Please enter the transaction password",
        "EnterGoogleVerificationCode": "Please enter the Google verification code",
        "WithdrawalsRecord": "Withdrawals record",
        "ExchangeCurrency": "Exchange currency",
        "ExchangeAmount": "Exchange amount",
        "ExchangeRate": "Exchange rate",
        "Expected": "Expected",
        "TransferRecord": "Transfer record",
        "CurrencyTransfer": "Currency to transfer",
        "Success": "Success",
        "EnterExchangeAmount": "Please enter the exchange amount"
    },
    "Account": {
        "Logout": "Logout",
        "PersonalCenter": "Personal center",
        "LoginHistory": "Login history",
        "Time": "Time",
        "Account": "Account",
        "LoginPassword": "Login password",
        "Modify": "Modify",
        "TransactionPassword": "Transaction password",
        "SetTransactionPassword": "Set transaction password",
        "ResetTransactionPassword": "Reset transaction password",
        "Recommend": "Recommend",
        "MobileAuth": "Mobile authentication",
        "BindMailbox": "Bind mailbox",
        "GoogleAuth": "Google authentication",
        "KYCCertification": "KYC certification",
        "MobileAuthDesc": "Used to retrieve passwords and modify security settings for security verification",
        "BindMailboxDesc": "Used to retrieve passwords and modify security settings for security verification",
        "GoogleAuthDesc": "Used to withdraw currency for security verification",
        "Add": "Add",
        "Remove": "Remove",
        "GoAuth": "Go to authentication",
        "Verified": "Verified",
        "UnderReview": "Under review",
        "ChangeLoginPassword": "Change login password",
        "ChangeLoginPasswordTips": "You cannot withdraw cash within 24 hours after the login password is changed, please operate with caution",
        "OriginalPassword": "Enter the original password",
        "NewPassword": "Enter a new password",
        "ConfirmPassword": "Enter the new password again",
        "EnterOriginalPassword": "Please enter the original password",
        "EnterNewPassword": "Please enter a new password",
        "EnterConfirmPassword": "Please enter new password again",
        "EnterPasswordError": "Password format is 6 to 20 characters",
        "EnterConfirmPasswordError": "The passwords entered twice are inconsistent",
        "ChangeTransactionPasswordTips": "You cannot withdraw cash within 24 hours after the transaction password is changed, please operate with caution",
        "EnterLoginPassword": "Enter login password",
        "PleaseEnterLoginPassword": "Please enter your password",
        "AddGoogleAuth": "Add Google Authenticator",
        "Step1": "Step 1 Download the `Google Authenticator` APP",
        "Step2": "Step 2 Add key in Google Authenticator and back it up",
        "Step3": "Step 3 Enter the 6-digit verification code from Google Validator",
        "Step1Desc": "iOS users log in to the App Store, search for `Authenticator` to download<br/><br/> Android users log in to the app store or use the mobile browser to search for `Google Authenticator` to download",
        "Step2Desc": "Open Google Authenticator, scan the QR code below or manually enter the key below to add a verification token",
        "CopyKey": "Copy key",
        "EnterGoogleVerificationCode": "Enter Google Verification Code",
        "PleaseEnterGoogleVerificationCode": "Please enter the Google verification code",
        "RemoveGoogleAuth": "Remove Google Validator",
        "RemoveGoogleAuthTips": "You cannot withdraw cash within 24 hours after deleting Google Validator, please operate with caution",
        "UploadIDPhoto": "Upload ID photo",
        "UploadIDPhotoTips": "Upload a color image of the entire document. Screenshots are not allowed. The size of the picture cannot exceed 10M, and it can only be in JPG or PNG format.",
        "FrontPhoto": "ID card front photo",
        "BackPhoto": "Photo of the reverse side of the ID card",
        "FrontPhotoTips": "Click to upload ID card front photo",
        "BackPhotoTips": "Click to upload a photo of the reverse side of your ID card"
    },
    "FundingType": {
        "All": "All",
        "system": "System operation",
        "commission": "Commission",
        "recharge": "Recharge",
        "withdraw": "Withdraw",
        "withdrawFail": "Withdrawal failed",
        "second_order": "Option contract order",
        "secondOrderWin": "Options contract win",
        "perpetual_order": "Perpetual futures order deposit + handling fee",
        "perpeTualOrderClo": "Perpetual futures Closing",
        "trade": "Currency transaction",
        "buyFinance": "Buy finance",
        "financeProfit": "Finance rebate",
        "financeMoney": "Finance back principal"
    },
    "Common": {
        "Language": "Language",
        "Tips": "Tips",
        "Confirm": "Confirm",
        "Cancel": "Cancel",
        "Submit": "Submit",
        "copy": "Copy",
        "CopySuccessfully": "Copy successfully",
		"Previous": "Previous",
		"Next": "Next",
        "NoData": "No data",
        "Loading": "Waiting...",
        "Back": "Back",
        "Details": "Details"
    }
}
