<template>
    <router-view />
</template>

<script>
	import bytetrack from "bytetrack"
	import lang_list from '@/utils/langList'
    export default {
        data() {
            return {
				curLang: ''
            }
        },
		created() {
			let lang = localStorage.getItem('lang') || 'tr'
			lang_list.forEach(item => {
			    if(item.key == lang) {
			        this.curLang = item.service_key
			    }
			})
			new bytetrack({
				appId: "RtnGSmwH",
				host: "https://chat.bytrack.xyz",
				lang: this.curLang
			})
			let userInfo = localStorage.getItem('user_info')
			if(userInfo) {
				let user_info = JSON.parse(userInfo)
				// console.log(user_info)
				bytetrack.userLogin({ userId: user_info.id })
				bytetrack.setUserInfo({
					phone: user_info.phone,
					email: user_info.email
				})
			}
		},
        methods: {
            
        }
    }
</script>