export default {
	"Module": {
		"Home": "Ev",
		"PerpetualContract": "Sürekli vadeli işlemler",
		"CurrencyTrading": "Para birimi işlemi",
		"SecondContract": "Opsiyon sözleşmesi",
		"Finance": "Finans",
		"OurTeam": "Ekibimiz",
		"Announcement": "Duyuru",
		"Assets": "Varlıklar",
		"Account": "Hesap"
	},
	"Login": {
		"Login": "Giriş yapmak",
		"Register": "Kayıt olmak",
		"RetrievePassword": "Şifreyi al",
		"Type": "E-posta",
		"Email": "E-posta",
		"Password": "Şifre",
		"ConfirmPassword": "Şifreyi Onayla",
		"VerificationCode": "Doğrulama kodu",
		"Send": "Göndermek",
		"PhoneNumber": "Telefon numarası",
		"InvitationCode": "Davetiye kodu",
		"Agree": "Okudum ve kabul ediyorum",
		"UserAgreement": "Kullanıcı Sözleşmesi",
		"EnterEmail": "Lütfen e-postanızı girin",
		"EnterEmailError": "Lütfen doğru e-posta formatını girin",
		"EnterPassword": "Lütfen şifrenizi giriniz",
		"EnterPasswordError": "Şifre formatı 6 ila 20 karakterdir",
		"EnterConfirmPassword": "Lütfen şifrenizi onaylayın",
		"EnterConfirmPasswordError": "İki kez girilen şifreler tutarsız",
		"EnterEmailFirst": "Lütfen önce e-postanızı girin",
		"EnterVerificationCode": "Lütfen doğrulama kodunu girin",
		"EnterPhoneNumber": "Lütfen cep telefonu numaranızı giriniz",
		"EnterInvitationCode": "Lütfen davet kodunu giriniz",
		"ConfirmAgreement": "Lütfen Kullanıcı Sözleşmesini okuyun ve kabul edin",
		"Forget": "Şifreyi mi unuttunuz?"
	},
	"Home": {
		"TcTitle": "Bildiri",
		"TopTitle1": "Kripto para yatırımınıza hızla başlayın",
		"TopTitle2": "NEODEAL, kripto para birimlerini satın almak ve satmak için en güvenilir ve en güvenli yerdir",
		"EmailAddress": "E-posta adresi",
		"StartUsing": "Kullanmaya başla",
		"SeeMore": "Daha fazlasını gör",
		"MarketTrend": "Piyasa trendi",
		"Currency": "Para birimi",
		"LatestPrice": "Son fiyat",
		"Change": "24 Saat Değişim",
		"TradingVolume": "24 Saatlik İşlem Hacmi",
		"Trend": "Eğilim",
		"Operate": "Çalıştırmak",
		"Trade": "Ticaret",
		"AllTradingVolume": "Borsa 24H işlem hacmi",
		"SupportedCountries": "Desteklenen ülkeler",
		"RegisterNumber": "Kayıtlı kullanıcı sayısı",
		"Box4Title": "En Güvenilir Kripto Para Platformu",
		"Box4Desc": "NEODEAL'ı seçmeniz için nedenler şunlardır",
		"Box4TabTitles": [
			"Çeşitlendirilmiş işlemler",
			"Asgari Ücret",
			"Önde gelen güvenlik",
			"En İyi Kullanıcı Deneyimi"
		],
		"Box4TabDescs": [
			"NEODEAL, çeşitli ticaret ihtiyaçlarını karşılamak için en üst düzeyde likiditeye sahip bir emir defteri, zengin kripto para ticareti ve varlık yönetimi hizmetleri sunmaktadır.",
			"NEODEAL, yatırımcılara eşler arası finansman piyasasına erişim sağlayarak, en düşük işlem ücretlerine sahip belirli kripto para spot işlem çiftlerini seçiyor.",
			"NEODEAL çevrimdışı depolama yöntemini benimsiyor ve kişisel işlem verileri uçtan uca şifreleme ile garanti altına alınıyor ve platform, kullanıcı varlıklarının güvenliğini tam olarak korumak için gerçek zamanlı izleme yapıyor.",
			"Kullanıcı odaklı, kullanımı kolay, geri bildirimleri dinleme, hızlı ürün optimizasyonu, 7*24 saat gerçek zamanlı çevrimiçi, çok dilli bire bir profesyonel hizmetler."
		],
		"Box5Title": "Çeşitli kullanıcıların ihtiyaçlarını karşılamak için ürün gruplarını sürekli zenginleştirmek",
		"Box5TabTitles": [
			"Sürekli vadeli işlemler",
			"Para birimi işlemi",
			"Opsiyon sözleşmesi",
			"Finans"
		],
		"Box5TabDescs": [
			"Kaldıraçlı işlem, kaldıraç kullanarak kendi fonlarını teminat olarak kullanıp, platformdan anapara olarak N katı kadar fon ödünç alıp, kar elde etmek için yatırımlar yapmaktır.",
			"Belirli bir dijital para birimini fiyatlandırma birimi olarak kullanın, diğer dijital para türlerini satın alın, kripto paraları en iyi fiyattan hızlıca alıp satın ve en iyi getiriyi elde edin.",
			"Finansal türevler, yatırımcıların kısa vadeli piyasa koşullarını analiz ederek, belirli bir zamanda, önceden belirlenmiş bir fiyattan alım veya satım yaparak, hızlı bir frekansta işlem yaparak kar elde etmelerini sağlayan işlemlerdir.",
			"Rekabetçi yüksek yıllık getiri oranı, birinci sınıf sermaye güvenlik garantisi, kolay kazanılan gelir!"
		],
		"TradeNow": "Şimdi ticaret yapın",
		"Box6Title1": "İşlemleri istediğiniz zaman, istediğiniz yerden başlatın",
		"Box6Title2": "Hem APP hem de WEB üzerinden hızlı bir şekilde işlem açılabilir",
		"FooterItems1": [
			"Şartlar ve Politikalar",
			"Oran standardı",
			"Risk uyarısı",
			"Kara para aklamaya karşı",
			"Hakkımızda"
		],
		"FooterItems2": [
			"Hizmet Gizliliği",
			"Hizmet Şartları",
			"Gizlilik bildirimi",
			"Beyaz kağıt",
			"MSB sertifikasyonu"
		],
		"FooterItems3": [
			"Yardım merkezi",
			"Başlangıç Rehberi",
			"Operasyon Kılavuzu",
			"Bize Ulaşın"
		]
	},
	"Trade": {
		"EnterCurrencyName": "Lütfen bir para birimi adı girin",
		"Currency": "Para birimi",
		"LatestPrice": "Son fiyat",
		"Change": "Değiştirmek",
		"Highest": "24 Saat Yüksek",
		"Lowest": "24 Saat Düşük",
		"OrderBook": "Sipariş defteri",
		"LatestRecord": "Son kayıt",
		"All": "Tüm",
		"Bids": "Teklifler",
		"Asks": "Sorular",
		"Price": "Fiyat",
		"Quantity": "Miktar",
		"Total": "Toplam",
		"Time": "Zaman",
		"AveragePrice": "Ortalama fiyat",
		"EnterPayPass": "Lütfen işlem şifresini giriniz",
		"BuyTips": "Satın almak istediğinize emin misiniz?",
		"type": "Tip"
	},
	"PerpetualContract": {
		"OrderType": "Sipariş türü",
		"Market": "Pazar",
		"EnterQuantity": "Satın alma miktarını girin",
		"HandlingFee": "İşlem ücreti",
		"AvailableBalance": "Mevcut bakiye",
		"Lever": "Kaldıraç",
		"Fee": "Ücret",
		"OpenLong": "Uzun süre açık",
		"OpenShort": "Kısa açık",
		"CurrentOrders": "Mevcut sipariş",
		"OrderHistory": "Sipariş geçmişi",
		"Margin": "Marj",
		"CurrentPrice": "Güncel fiyat",
		"OpeningPrice": "Açılış fiyatı",
		"UnsettledProfit": "Belirlenmemiş kar",
		"Operate": "Çalıştırmak",
		"OpenTime": "Açık zaman",
		"ClosingTime": "Kapanış saati",
		"ClosingPrice": "Kapanış fiyatı",
		"SettlementProfit": "Yerleşim karı",
		"ClosePosition": "Yakın pozisyon",
		"Edit": "Düzenlemek",
		"EditOrder": "Siparişi düzenle",
		"EnterCloseQuantity": "Lütfen miktarı giriniz",
		"ConfirmOrder": "Siparişi onayla",
		"TakeProfitPrice": "Kar alma fiyatı",
		"StopPrice": "Durdurma fiyatı"
	},
	"CurrencyTrading": {
		"Buy": "Satın almak",
		"Sell": "Satmak",
		"MyBalance": "Benim bakiyem",
		"AvailablePurchaseAmount": "Satın alınabilecek miktar",
		"ConvertibleAmount": "Dönüştürülebilir miktar",
		"CurrentPrice": "Güncel fiyat",
		"BuyQuantity": "Miktar satın al",
		"EnterBuyQuantity": "Satın alınacak miktarı giriniz",
		"SellQuantity": "Satış miktarı",
		"EnterSellQuantity": "Satılacak miktarı girin",
		"RequiredAmount": "Gerekli miktar",
		"ExchangeAmount": "Döviz tutarı",
		"OrderHistory": "Sipariş geçmişi",
		"Status": "Durum",
		"Success": "Başarı"
	},
	"SecondContract": {
		"Buy": "Satın almak",
		"OpeningQuantity": "Açılış miktarı",
		"EnterOpeningQuantity": "Para birimi tutarını girin",
		"SelectQuantity": "Miktarı seçin",
		"SelectMultiple": "Birden fazla seçin",
		"OpeningTime": "Açılış saati",
		"minute": "dakika",
		"Balance": "Denge",
		"Transfer": "Aktarım",
		"HandlingFee": "İşlem ücreti",
		"Profitability": "Karlılık",
		"BuyUp": "Satın al",
		"BuyDown": "Aşağı satın al",
		"InTransaction": "İşlemde",
		"Closed": "Kapalı",
		"OrderNumber": "Sipariş numarası",
		"CurrentPrice": "Güncel fiyat",
		"Direction": "Yön",
		"EstimatedProfitLoss": "Tahmini kar ve zarar",
		"Countdown": "Geri sayım",
		"OpeningPrice": "Açılış fiyatı",
		"ClosingPrice": "Kapanış fiyatı",
		"ProfitAndLoss": "Kar ve zarar"
	},
	"Finance": {
		"TopTitle": "Finans hazinesi",
		"TopDesc": "Sabit (kilitli finansal yönetim), cari finansal yönetim ve diğer ürün türleri dahil olmak üzere faiz kazanmak için para birimi tutan bir blok zinciri varlık yatırım platformudur ve istikrarlı yatırım kullanıcıları için en iyi yatırım kanalını sağlar.",
		"AllProducts": "Tüm ürünler",
		"InProgress": "Devam etmekte",
		"AboutToStart": "Başlamak üzere",
		"ItsOver": "Bitti",
		"FinancialRecords": "Mali kayıtlar",
		"ReferenceAnnualized": "Referans yıllık",
		"day": "gün",
		"hour": "saat",
		"minute": "dakika",
		"second": "ikinci",
		"Unlock": "Kilidini aç",
		"RemainingPosition": "Kalan pozisyon",
		"LockedPositions": "Kilitli pozisyonlar",
		"EndOfDistance": "Mesafenin sonu",
		"JoinNow": "Şimdi katılın",
		"OurAdvantage": "Avantajımız",
		"AdvanListTitles": [
			"Verimli gelir",
			"Daha kısa döngü",
			"Güvenli ve güvenilir"
		],
		"AdvanListDescs": [
			"Kullanıcılar için yüksek kaliteli, yüksek getirili finansal ürünler yaratmak amacıyla çeşitli profesyonel finansal araç kombinasyonlarını kullanın",
			"Döngü daha esnektir ve kilitlenme süresi 7 güne kadar kısadır",
			"Profesyonel yönetim ve risk kontrol çözümleri varlık getirileri için koruma sağlar"
		],
		"JoinNum": "{joinNum} kişi katıldı",
		"DailyRate": "Günlük ücret",
		"StartingTime": "Başlangıç zamanı",
		"LockPeriod": "Kilitlenme süresi",
		"RebateMethod": "İndirim yöntemi",
		"RebateMethod1": "Vade sonunda anapara iadesi",
		"RebateMethod2": "Günlük indirim",
		"LockPosition": "Kilit pozisyonu",
		"ProductSpecification": "Ürün özellikleri",
		"LockedCurrency": "Kilitli para birimi",
		"SubscriptionAmount": "Abonelik tutarı",
		"MinAndMax": "Minimum/Maksimum",
		"TotalPosition": "Toplam pozisyon",
		"TradingRules": "Ticaret kuralları",
		"BuyProductTips": "Bu ürüne abone olmak istediğinizden emin misiniz?",
		"Pending": "Askıda olması",
		"Completed": "Tamamlanmış",
		"ProductName": "Ürün adı",
		"PurchaseAmount": "Satın alma tutarı",
		"CumulativeIncome": "Toplam gelir",
		"EndTime": "Son Zaman"
	},
	"Assets": {
		"FinancialRecords": "Mali kayıtlar",
		"Deposit": "Depozito",
		"Withdraw": "Geri çekilmek",
		"Transfer": "Aktarım",
		"TotalBalance": "Toplam bakiye",
		"Refresh": "Yenile",
		"Currency": "Para birimi",
		"AvailableBalance": "Mevcut bakiye",
		"Frozen": "Dondurulmuş",
		"Operate": "Çalıştırmak",
		"Type": "Tip",
		"Time": "Zaman",
		"Quantity": "Miktar",
		"SelectCurrency": "Para birimini seçin",
		"Address": "Adres",
		"Notice": "Fark etme",
		"DepositRecord": "Mevduat kaydı",
		"OrderNumber": "Sipariş numarası",
		"Status": "Durum",
		"Status1": "Devam etmekte",
		"Status2": "Tamamlanmış",
		"Status3": "Hata",
		"Status4": "Anormal",
		"Status5": "İptal edildi",
		"WithdrawalAmount": "Çekilecek Miktar",
		"Least": "En az",
		"All": "Tüm",
		"WithdrawalCurrency": "Para çekme para birimi",
		"WithdrawalAddress": "Para çekme adresi",
		"HandlingFee": "İşlem ücreti",
		"ReceiptAmount": "Makbuz tutarı",
		"TransactionPassword": "İşlem şifresi",
		"SetTransactionPassword": "İşlem şifresini ayarlayın",
		"GoogleVerificationCode": "Google doğrulama kodu",
		"EnterWithdrawalAmount": "Lütfen çekme tutarını girin",
		"EnterWithdrawalAddress": "Lütfen para çekme adresini girin",
		"EnterTransactionPassword": "Lütfen işlem şifresini giriniz",
		"EnterGoogleVerificationCode": "Lütfen Google doğrulama kodunu girin",
		"WithdrawalsRecord": "Para çekme kaydı",
		"ExchangeCurrency": "Döviz bozdurma",
		"ExchangeAmount": "Döviz tutarı",
		"ExchangeRate": "Döviz kuru",
		"Expected": "Beklenen",
		"TransferRecord": "Transfer kaydı",
		"CurrencyTransfer": "Transfer edilecek para birimi",
		"Success": "Başarı",
		"EnterExchangeAmount": "Lütfen döviz tutarını giriniz"
	},
	"Account": {
		"Logout": "Çıkış Yap",
		"PersonalCenter": "Kişisel merkez",
		"LoginHistory": "Giriş geçmişi",
		"Time": "Zaman",
		"Account": "Hesap",
		"LoginPassword": "Giriş şifresi",
		"Modify": "Değiştir",
		"TransactionPassword": "İşlem şifresi",
		"SetTransactionPassword": "İşlem şifresini ayarlayın",
		"ResetTransactionPassword": "İşlem şifresini sıfırla",
		"Recommend": "Tavsiye etmek",
		"MobileAuth": "Mobil kimlik doğrulama",
		"BindMailbox": "Posta kutusunu bağla",
		"GoogleAuth": "Google kimlik doğrulaması",
		"KYCCertification": "KYC sertifikasyonu",
		"MobileAuthDesc": "Güvenlik doğrulaması için parolaları almak ve güvenlik ayarlarını değiştirmek için kullanılır",
		"BindMailboxDesc": "Güvenlik doğrulaması için parolaları almak ve güvenlik ayarlarını değiştirmek için kullanılır",
		"GoogleAuthDesc": "Güvenlik doğrulaması için para çekmek amacıyla kullanılır",
		"Add": "Eklemek",
		"Remove": "Kaldırmak",
		"GoAuth": "Kimlik doğrulamaya git",
		"Verified": "Doğrulandı",
		"UnderReview": "İnceleniyor",
		"ChangeLoginPassword": "Giriş şifresini değiştir",
		"ChangeLoginPasswordTips": "Giriş şifresi değiştirildikten sonra 24 saat içinde para çekemezsiniz, lütfen dikkatli olun",
		"OriginalPassword": "Orijinal şifreyi girin",
		"NewPassword": "Yeni bir şifre girin",
		"ConfirmPassword": "Yeni şifreyi tekrar girin",
		"EnterOriginalPassword": "Lütfen orijinal şifreyi girin",
		"EnterNewPassword": "Lütfen yeni bir şifre girin",
		"EnterConfirmPassword": "Lütfen yeni şifrenizi tekrar giriniz",
		"EnterPasswordError": "Şifre formatı 6 ila 20 karakterdir",
		"EnterConfirmPasswordError": "İki kez girilen şifreler tutarsız",
		"ChangeTransactionPasswordTips": "İşlem şifresi değiştirildikten sonra 24 saat içerisinde para çekimi yapamazsınız, lütfen dikkatli hareket edin.",
		"EnterLoginPassword": "Giriş şifresini girin",
		"PleaseEnterLoginPassword": "Lütfen şifrenizi giriniz",
		"AddGoogleAuth": "Google Authenticator'ı ekleyin",
		"Step1": "Adım 1 `Google Authenticator` UYGULAMASINI indirin",
		"Step2": "Adım 2 Google Authenticator'a anahtar ekleyin ve yedekleyin",
		"Step3": "Adım 3 Google Doğrulayıcı'dan 6 haneli doğrulama kodunu girin",
		"Step1Desc": "iOS kullanıcıları App Store'a giriş yapın, indirmek için `Authenticator`ı arayın<br/><br/> Android kullanıcıları, indirmek için uygulama mağazasına giriş yapar veya mobil tarayıcıyı kullanarak `Google Authenticator` araması yapar",
		"Step2Desc": "Google Authenticator'ı açın, aşağıdaki QR kodunu tarayın veya doğrulama belirteci eklemek için aşağıdaki anahtarı manuel olarak girin",
		"CopyKey": "Anahtar kopyala",
		"EnterGoogleVerificationCode": "Google Doğrulama Kodunu Girin",
		"PleaseEnterGoogleVerificationCode": "Lütfen Google doğrulama kodunu girin",
		"RemoveGoogleAuth": "Google Doğrulayıcıyı Kaldır",
		"RemoveGoogleAuthTips": "Google Doğrulayıcıyı sildikten sonra 24 saat içinde para çekemezsiniz, lütfen dikkatli çalışın",
		"UploadIDPhoto": "Kimlik fotoğrafınızı yükleyin",
		"UploadIDPhotoTips": "Tüm belgenin renkli bir görüntüsünü yükleyin. Ekran görüntüleri kabul edilmez. Resmin boyutu 10M'yi geçemez ve yalnızca JPG veya PNG formatında olabilir.",
		"FrontPhoto": "Kimlik kartı ön yüzü fotoğrafı",
		"BackPhoto": "Kimlik kartının arka yüzünün fotoğrafı",
		"FrontPhotoTips": "Kimlik kartı ön yüzünün fotoğrafını yüklemek için tıklayın",
		"BackPhotoTips": "Kimlik kartınızın arka yüzünün fotoğrafını yüklemek için tıklayın"
	},
	"FundingType": {
		"All": "Tüm",
		"system": "Sistemin çalışması",
		"commission": "Komisyon",
		"recharge": "Yeniden şarj et",
		"withdraw": "Geri çekilmek",
		"withdrawFail": "Para çekme işlemi başarısız oldu",
		"second_order": "Opsiyon sözleşmesi emri",
		"secondOrderWin": "Opsiyon sözleşmesi kazanır",
		"perpetual_order": "Sürekli vadeli işlem emri depozitosu + işlem ücreti",
		"perpeTualOrderClo": "Sürekli vadeli işlemler Kapanış",
		"trade": "Para birimi işlemi",
		"buyFinance": "Finansman satın al",
		"financeProfit": "Finansman indirimi",
		"financeMoney": "Finansman anaparayı geri al"
	},
	"Common": {
		"Language": "Dil",
		"Tips": "İpuçları",
		"Confirm": "Onaylamak",
		"Cancel": "İptal etmek",
		"Submit": "Göndermek",
		"copy": "Kopyala",
		"CopySuccessfully": "Kopyalama başarıyla gerçekleştirildi",
		"Previous": "Öncesi",
		"Next": "Sonraki",
		"NoData": "Veri yok",
		"Loading": "Beklemek...",
		"Back": "Geri",
		"Details": "Detaylar"
	}
}