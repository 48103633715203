export default [
    {
        key: 'en',
		service_key: 'en_US',
        value: 'English'
    },
    {
        key: 'es',
		service_key: 'es_ES',
        value: 'Español'
    },
    {
        key: 'pt',
		service_key: 'pt_BR',
        value: 'Português'
    },
	{
	    key: 'tr',
		service_key: 'tr_TR',
	    value: 'Türkçe'
	},
    {
        key: 'ja',
		service_key: 'ja_JP',
        value: '日本語'
    },
    {
        key: 'ko',
		service_key: 'ko_KR',
        value: '한국인'
    },
    {
        key: 'zh-CN',
		service_key: 'zh_CN',
        value: '中文'
    }
]